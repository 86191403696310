import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { doc, setDoc } from 'firebase/firestore'

import { Box, IconButton, Stack } from '@mui/material'
import { Clear, Send } from '@mui/icons-material'

import { useAppSelector } from '@hooks/redux'
import { useAuth } from '@hooks/useAuth'
import { generateUniqueId } from '@utils/generateUniqueId'
import { BorderBox } from '@ui/ThemeBox'
import { ThemeAvatar } from '@ui/ThemeAvatar'
import { ThemeTextFieldAddPost } from '@ui/ThemeTextField'

import { AddPhotos } from './AddPhotos'
import { AddVideo } from './AddVideo'
import { AddEmoji } from './AddEmoji'

export const AddPost: FC = () => {
  const { t } = useTranslation(['news'])
  const { db } = useAuth()

  const { emoji, uid, displayName, photoURL, verified, oac } = useAppSelector(
    (state) => state.user
  )

  const [content, setContent] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [imagesIdDb, setImagesIdDb] = useState<string>('')
  const [video, setVideo] = useState<string[]>([])
  const [videoIdDb, setVideoIdDb] = useState<string>('')

  const handleSendPost = async (e: any) => {
    if (!content.replaceAll('ㅤ', '').trim() && images.length === 0 && video.length === 0) return
    const uniqueId = generateUniqueId()

    try {
      await setDoc(doc(db, 'posts', imagesIdDb || uniqueId), {
        author: { uid, displayName, photoURL, emoji, verified, oac },
        content: content.replaceAll('ㅤ', '').trim(),
        createdAt: Date.now(),
        comments: [],
        likes: [],
        bookmarks: [],
        images,
        video,
        views: 0,
        id: imagesIdDb || uniqueId,
        promoted: false
      })

      setContent('')
    } catch (e) {
      console.error('Error adding document: ', e)
    }
    setContent('')
    setImagesIdDb('')
    setImages([])
    setVideoIdDb('')
    setVideo([])
    e.target.blur()
  }

  const handleDeleteImage = (imageUrl: string) => {
    const newImagesArr = images.filter((image) => image !== imageUrl)
    setImages(newImagesArr)
  }

  const handleDeleteVideo = (videoUrl: string) => {
    const newVideoArr = video.filter((video) => video !== videoUrl)
    setVideo(newVideoArr)
  }

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
      <Stack alignItems="center" direction="row">
        <Link to={`/profile/${uid}`}>
          <ThemeAvatar sx={{ display: { xs: 'none', sm: 'block' } }} alt={displayName || ''} src={photoURL || ''}>
            {emoji}
          </ThemeAvatar>
        </Link>
        <ThemeTextFieldAddPost
          label={<b>{t(`What's new?`)}</b>}
          multiline
          fullWidth
          autoComplete="off"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          sx={{ mx: 2 }}
        />
        <AddEmoji setContent={setContent} />
        {video.length === 0 && <AddPhotos setImages={setImages} setImagesIdDb={setImagesIdDb} />}
        {video.length === 0 && <AddVideo setVideo={setVideo} setVideoIdDb={setVideoIdDb} />}
        <IconButton
          color="primary"
          onClick={handleSendPost}
          title={t('Post', { ns: ['other'] }) || ''}
          sx={{ width: '50px ', height: '50px', mx: -1 }}
        >
          <Send />
        </IconButton>
      </Stack>
      {images.length > 0 && (
        <Stack direction="row" sx={{ mt: 3, flexWrap: 'wrap', gap: 1 }}>
          {images.map((image) => (
            <Stack direction="row" key={image}>
              <Box sx={{ width: '78px', height: '78px' }}>
                <img
                  src={image}
                  alt={image}
                  height="78px"
                  width="78px"
                  draggable={false}
                  className="cover"
                />
              </Box>
              <IconButton
                onClick={() => handleDeleteImage(image)}
                color="secondary"
                sx={{ height: '30px', width: '30px', mt: -1 }}
              >
                <Clear sx={{ height: '20px', width: '20px' }} />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      )}
      {video.length > 0 && (
        <Stack sx={{ mt: 3 }}>
      {video.map((video) => (
        <Stack direction="row" alignContent={"center"} key={video}>
          <video
              src={video}
              autoPlay={true}
              draggable={false}
              className="cover"
              loop={true}
              height="300px"
            />
          <IconButton
            onClick={() => handleDeleteVideo(video)}
            color="secondary"
            sx={{ height: '30px', width: '30px', mt: -1 }}
          >
            <Clear sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Stack>
      ))}
      </Stack>
      )}
    </BorderBox>
  )
}
